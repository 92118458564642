import React from 'react';
import RegistrationControls from './RegistrationControls';
import { ListItem, ListItemText, Divider } from '@mui/material';

const AgendaItem = ({ item, refreshAgenda }) => {
    return (
        <>
            <ListItem>
                <ListItemText
                    primary={item.title}
                    secondary={`Bereits angemeldete Redezeit: ${item.time_registered} / ${item.max_additional_redezeit} Minuten`}
                />
                <RegistrationControls
                    agendaId={item.id}
                    isOwn={item.is_own}
                    timeRegistered={item.time_registered}
                    maxTime={item.max_additional_redezeit}
                    refreshAgenda={refreshAgenda}
                />
            </ListItem>
            <Divider />
        </>
    );
};

export default AgendaItem;
