import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import ManageAgenda from './ManageAgenda';
import ManageBudgets from './ManageBudgets';
import { Box, Button, Typography } from '@mui/material';

const AdminDashboard = () => {
    return (
        <Box>
            <Typography variant="h4" mt={4} mb={2}>
                Verwaltungs-Dashboard
            </Typography>
            <Box mb={2}>
                <Button variant="contained" component={Link} to="manage-agenda" sx={{ mr: 2 }}>
                    Tagesordnungspunkte verwalten
                </Button>
                <Button variant="contained" component={Link} to="manage-budgets">
                    Budgets verwalten
                </Button>
            </Box>
            <Routes>
                <Route path="manage-agenda" element={<ManageAgenda />} />
                <Route path="manage-budgets" element={<ManageBudgets />} />
            </Routes>
        </Box>
    );
};

export default AdminDashboard;
