import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import Login from './components/Auth/Login';
import Dashboard from './components/Dashboard';
import AdminDashboard from './components/Admin/AdminDashboard';
import Navbar from './components/Layout/Navbar';

const App = () => {
    const { auth } = useContext(AuthContext);

    const PrivateRoute = ({ children }) => {
        return auth.token ? children : <Navigate to="/login" />;
    };

    const AdminRoute = ({ children }) => {
        return auth.user && auth.user.role === 'Verwaltung' ? children : <Navigate to="/dashboard" />;
    };

    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                } />
                <Route path="/admin/*" element={
                    <PrivateRoute>
                        <AdminRoute>
                            <AdminDashboard />
                        </AdminRoute>
                    </PrivateRoute>
                } />
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="*" element={<h1>404 Not Found</h1>} />
            </Routes>
        </Router>
    );
};

export default App;

