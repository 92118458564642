import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Typography } from '@mui/material';

const ManageBudgets = () => {
    const [budgets, setBudgets] = useState([]);
    const [editing, setEditing] = useState(null);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState('');

    useEffect(() => {
        fetchBudgets();
    }, []);

    const fetchBudgets = async () => {
        try {
            const response = await api.get('/api/budget');
            setBudgets(response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen der Budgets:', error);
        }
    };

    const handleEdit = (fraktion) => {
        setEditing(fraktion);
        setFormData({
            standard_redezeit: fraktion.standard_redezeit,
            max_additional_redezeit: fraktion.max_additional_redezeit,
            standard_einbringung: fraktion.standard_einbringung,
            max_additional_einbringung: fraktion.max_additional_einbringung
        });
        setError('');
    };

    const handleCancel = () => {
        setEditing(null);
        setFormData({});
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleUpdate = async (fraktion_id) => {
        try {
            await api.patch(`/api/budgets/${fraktion_id}`, formData);
            fetchBudgets();
            setEditing(null);
        } catch (err) {
            setError(err.response?.data?.message || 'Fehler beim Aktualisieren des Budgets.');
        }
    };

    return (
        <Paper>
            <Typography variant="h6" gutterBottom align="center" mt={2}>
                Budgets verwalten
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Fraktion</TableCell>
                            <TableCell>Standardredezeit</TableCell>
                            <TableCell>Max. zusätzliche Redezeit</TableCell>
                            <TableCell>Standard Einbringung</TableCell>
                            <TableCell>Max. zusätzliche Einbringung</TableCell>
                            <TableCell>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {budgets.map((budget) => (
                            <TableRow key={budget.fraktion}>
                                <TableCell>{budget.fraktion}</TableCell>
                                <TableCell>
                                    {editing && editing.fraktion === budget.fraktion ? (
                                        <TextField
                                            name="standard_redezeit"
                                            type="number"
                                            value={formData.standard_redezeit}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        budget.remaining_time
                                    )}
                                </TableCell>
                                <TableCell>{budget.remaining_time}</TableCell>
                                <TableCell>{budget.standard_einbringung}</TableCell>
                                <TableCell>{budget.max_additional_einbringung}</TableCell>
                                <TableCell>
                                    {editing && editing.fraktion === budget.fraktion ? (
                                        <>
                                            <Button variant="contained" color="primary" onClick={() => handleUpdate(budget.fraktion_id)} sx={{ mr: 1 }}>
                                                Speichern
                                            </Button>
                                            <Button variant="outlined" color="secondary" onClick={handleCancel}>
                                                Abbrechen
                                            </Button>
                                        </>
                                    ) : (
                                        <Button variant="outlined" onClick={() => handleEdit(budget)}>
                                            Bearbeiten
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {error && <Typography color="error" align="center" mt={2}>{error}</Typography>}
        </Paper>
    );
};

export default ManageBudgets;
