import React, { useState, useEffect, useContext } from 'react';
import api from '../utils/api';
import { AuthContext } from '../contexts/AuthContext';
import AgendaItem from './AgendaItem';
import { List, Typography, Box } from '@mui/material';

const AgendaList = () => {
    const [agenda, setAgenda] = useState([]);
//    const { auth } = useContext(AuthContext);

    useEffect(() => {
        fetchAgenda();
        const interval = setInterval(fetchAgenda, 60000); // Aktualisierung alle 60 Sekunden
        return () => clearInterval(interval);
    }, []);

    const fetchAgenda = async () => {
        try {
            const response = await api.get('/api/agenda');
            setAgenda(response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen der Agenda:', error);
        }
    };

    return (
        <Box mt={4}>
            <Typography variant="h5" gutterBottom>
                Noch nicht aufgerufene Tagesordnungspunkte
            </Typography>
            <List>
                {agenda.map(item => (
                    <AgendaItem key={item.id} item={item} refreshAgenda={fetchAgenda} />
                ))}
            </List>
        </Box>
    );
};

export default AgendaList;
