import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const ManageAgenda = () => {
    const [agenda, setAgenda] = useState([]);
    const [open, setOpen] = useState(false);
    const [newAgenda, setNewAgenda] = useState({ title: '', order_number: '' });
    const [error, setError] = useState('');

    useEffect(() => {
        fetchAgenda();
    }, []);

    const fetchAgenda = async () => {
        try {
            const response = await api.get('/api/agenda');
            setAgenda(response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen der Agenda:', error);
        }
    };

    const handleOpen = () => {
        setNewAgenda({ title: '', order_number: '' });
        setError('');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddAgenda = async () => {
        const { title, order_number } = newAgenda;
        if (!title || !order_number) {
            setError('Titel und Reihenfolge sind erforderlich.');
            return;
        }

        try {
            await api.post('/api/agenda', { title, order_number });
            fetchAgenda();
            handleClose();
        } catch (err) {
            setError(err.response?.data?.message || 'Fehler beim Hinzufügen des Tagesordnungspunkts.');
        }
    };

    return (
        <Paper>
            <Typography variant="h6" gutterBottom align="center" mt={2}>
                Tagesordnungspunkte verwalten
            </Typography>
            <Button variant="contained" color="primary" onClick={handleOpen} sx={{ m: 2 }}>
                Neuer Tagesordnungspunkt
            </Button>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Titel</TableCell>
                            <TableCell>Reihenfolge</TableCell>
                            <TableCell>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {agenda.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.title}</TableCell>
                                <TableCell>{item.order_number}</TableCell>
                                <TableCell>
                                    {/* Hier können weitere Aktionen wie Bearbeiten oder Löschen hinzugefügt werden */}
                                    <Button variant="outlined" color="secondary" size="small">
                                        Bearbeiten
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog zum Hinzufügen eines neuen Tagesordnungspunkts */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Neuer Tagesordnungspunkt</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Titel"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newAgenda.title}
                        onChange={(e) => setNewAgenda({ ...newAgenda, title: e.target.value })}
                        required
                    />
                    <TextField
                        margin="dense"
                        label="Reihenfolge"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={newAgenda.order_number}
                        onChange={(e) => setNewAgenda({ ...newAgenda, order_number: e.target.value })}
                        required
                    />
                    {error && <Typography color="error">{error}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button onClick={handleAddAgenda} variant="contained">Hinzufügen</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default ManageAgenda;
