import React, { useState, useEffect, useContext } from 'react';
import api from '../utils/api';
import { AuthContext } from '../contexts/AuthContext';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const BudgetDisplay = () => {
    const [budgets, setBudgets] = useState([]);
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        fetchBudgets();
        const interval = setInterval(fetchBudgets, 60000); // Aktualisierung alle 60 Sekunden
        return () => clearInterval(interval);
    }, []);

    const fetchBudgets = async () => {
        try {
            const response = await api.get('/api/budget', {
                params: auth.user.role === 'Verwaltung' ? {} : { fraktion_id: auth.user.fraktion_id }
            });
            setBudgets(response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen des Budgets:', error);
        }
    };

    return (
        <Grid container spacing={2} mt={2}>
            {budgets.map((budget) => (
                <Grid item xs={12} sm={6} md={4} key={budget.fraktion}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">{budget.fraktion}</Typography>
                            <Typography variant="body1">
                                Verbleibende Redezeit: {budget.remaining_time} Minuten
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default BudgetDisplay;
