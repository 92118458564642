import React, { useState, useContext } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { FaPlus, FaMinus } from 'react-icons/fa';
import api from '../utils/api';
import { AuthContext } from '../contexts/AuthContext';

const RegistrationControls = ({ agendaId, isOwn, timeRegistered, maxTime, refreshAgenda }) => {
    const [error, setError] = useState('');
//    const { auth } = useContext(AuthContext);

    const handleRegister = async () => {
        if (timeRegistered >= maxTime) {
            setError('Maximale Redezeit erreicht.');
            return;
        }

        try {
            await api.post(`/api/agenda/${agendaId}/register`, { minutes: 1 });
            refreshAgenda();
            setError('');
        } catch (err) {
            setError(err.response?.data?.message || 'Fehler beim Anmelden.');
        }
    };

    const handleDeregister = async () => {
        if (timeRegistered <= 0) {
            setError('Keine Redezeit zum Abmelden vorhanden.');
            return;
        }

        try {
            await api.post(`/api/agenda/${agendaId}/deregister`, { minutes: 1 });
            refreshAgenda();
            setError('');
        } catch (err) {
            setError(err.response?.data?.message || 'Fehler beim Abmelden.');
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" mb={1}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    size="small" 
                    onClick={handleRegister} 
                    disabled={timeRegistered >= maxTime}
                    startIcon={<FaPlus />}
                    sx={{ mr: 1 }}
                >
                    +1
                </Button>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    size="small" 
                    onClick={handleDeregister} 
                    disabled={timeRegistered <= 0}
                    startIcon={<FaMinus />}
                >
                    -1
                </Button>
            </Box>
            {isOwn && (
                <Box display="flex">
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        size="small" 
                        onClick={handleRegister} 
                        disabled={timeRegistered >= maxTime}
                        startIcon={<FaPlus />}
                        sx={{ mr: 1 }}
                    >
                        Aussprache +1
                    </Button>
                    <Button 
                        variant="outlined" 
                        color="secondary" 
                        size="small" 
                        onClick={handleDeregister} 
                        disabled={timeRegistered <= 0}
                        startIcon={<FaMinus />}
                    >
                        Aussprache -1
                    </Button>
                </Box>
            )}
            {error && <Typography color="error" variant="caption">{error}</Typography>}
        </Box>
    );
};

export default RegistrationControls;
