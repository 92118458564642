import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import BudgetDisplay from './BudgetDisplay';
import AgendaList from './AgendaList';
import { Container, Typography } from '@mui/material';

const Dashboard = () => {
    const { auth } = useContext(AuthContext);

    return (
        <Container>
            <Typography variant="h4" mt={4} mb={2}>
                {auth.user.role === 'Verwaltung' ? 'Verwaltungs-Dashboard' : 'Fraktions-Dashboard'}
            </Typography>
            <BudgetDisplay />
            <AgendaList />
        </Container>
    );
};

export default Dashboard;
